import React, { useEffect, useState } from 'react'

import TagManager from 'react-gtm-module-nonce-aware/dist/TagManager'
import Categories from '../../components/Categories'
import Hero from '../../components/Hero'
import { default as ProductCard, default as Products } from '../../components/ProductCard'
import Stats from '../../components/StatCard'



const tagManagerArgs = {
  dataLayer: {
      userId: '001',
      userProject: 'project',
      page: 'home'
  },
  
}
const Home = () => {
  TagManager.dataLayer(tagManagerArgs);
  const [products, setProducts] = useState([])
  useEffect(() => {
    const fetchProducts = async () => {
      const response = await fetch('https://fakestoreapi.com/products?limit=12')
      const data = await response.json()
      console.log(data)
      setProducts(data)
    }
    fetchProducts()
  }, [])
  if (products.length > 0) {
    TagManager.dataLayer({ dataLayer : { ecommerce: null }});
    TagManager.dataLayer({
      dataLayer: {
        event: "view_item_list",
        ecommerce: {
          currencyCode: "USD",
          impressions: products.map((product, index) => {
            return {
              item_id: product.id,
              item_name: product.title,
              item_brand: "Google",
              item_category: product.category,
              item_category2: "Adult",
              item_category3: "Shirts",
              item_category4: "Crew",
              item_category5: "Short sleeve",
              item_list_id: "related_products",
              item_list_name: "Related Products",
              item_variant: "green",
              location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
              price: product.price,
              index: index
            }
          })
        }
      }
    });
  }
  return (
    <>
      <Hero />
      <Categories/>
      <div className="flex flex-col text-center w-full mt-20">
        <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">PRODUCTS</h2>
        <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900">MOST POPULAR PRODUCTS</h1>
      </div>
      {
        
        products.length > 0 ? 
        <ProductCard products={products} /> 
        :
        <div>Loading.....</div>
      }
      <Products />
      <Stats/>
    </>
  )
}

export default Home